import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Notifier } from 'common/ui';
import { Members } from 'services/members';
import { Sso } from 'services/sso';
import { Dialer } from 'services/dialer';
import { Calendar } from 'services/calendar';

export class AgentSettings {
    static inject = [Security, Notifier, Members, Sso, Dialer, Calendar];
    @bindable memberId = null;

    security;
    _notifier;
    _members;
    _sso;
    _dialer;
    _calendar;

    dialerTeams;

    constructor(security, notifier, members, sso, dialer, calendar) {
        this.security = security;
        this._notifier = notifier;
        this._members = members;
        this._sso = sso;
        this._dialer = dialer;
        this._calendar = calendar;
    }

    attached() {
        this._isAttached = true;
        this._load();
    }

    memberIdChanged(newValue, oldValue) {
        if (oldValue === newValue) return;
        if (!this._isAttached) return;
        this._load();
    }

    async _load() {
        if (!this.memberId) return;
        try {
            this.isMyProfile = this.security.isAuthenticatedMember(this.memberId);
            const loaders = [
                this._members.settings(this.memberId, 'code'),
                this._members.settings(this.memberId, 'admin-code'),
                this._sso.suranceBayAffiliates(),
                this._calendar.bookingCalendars(this.memberId),
            ];
            if (this.security.isAdmin) loaders.push(this._dialer.teams());
            const data = await Promise.all(loaders);
            this.codes = data[0];
            this.adminCodes = data[1];
            this.sbAffiliates = data[2];
            this.bookingCalendars = data[3];
            if (this.security.isAdmin) {
                this.dialerTeams = data[4];
            }
        } catch (err) {
            console.log(err);
        }
    }

    isDialerTeam(key) {
        return key.indexOf('dialer-team') === 0;
    }

    async saveCodes() {
        try {
            this.saving = true;
            await this._members.saveSettings(this.memberId, 'code', { settings: this.codes });
            this._notifier.success('agent-settings-saved-message');
        } catch (err) {
            console.log(err);
            this._notifier.error('agent-settings-saved-error');
        } finally {
            this.saving = false;
        }
    }

    async saveAdminCodes() {
        try {
            this.saving = true;
            await this._members.saveSettings(this.memberId, 'admin-code', { settings: this.adminCodes });
            this._notifier.success('agent-settings-saved-message');
        } catch (err) {
            console.log(err);
            this._notifier.error('agent-settings-saved-error');
        } finally {
            this.saving = false;
        }
    }

    toggleView() {
        this.showMyCodes = !this.showMyCodes;
    }
}
